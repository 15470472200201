/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import NavBar from "../Components/NavBar";
import CardPressure from "../Components/Card/CardPressure";
// import './site.css'
import CardLPCD from "../Components/Card/CardLPCD";
import CardChlorine from "../Components/Card/CardChlorine";
import CardWatersupplied from "../Components/Card/CardWatersupplied";
import CardTurbidity from "../Components/Card/CardTurbidity";
import CardOHRS from "../Components/Card/CardOHRS";
import { Badge } from 'antd';
import avtarImage from "../assets/images/icon-user.svg";
import MapComponent from "../Components/Map/MapComponent";
import mapMarker from "../assets/images/member.svg";
import { getSiteDetails, pushMqttData, getProjectDetails } from '../redux/actions/siteAction'
import { types } from '../redux/actions/types';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// import ButtonWithSpinner from "../Components/ButtonWithSpinner";
import SensorValue from "../Components/SensorValue";
import { isEmpty, checkPermission, getData, CONSTANTVALUE } from '../utils';
import { setIMEINumber } from '../redux/actions/settingAction';
import { initializeApp } from "firebase/app";
import image1 from '../../src/assets/case1.gif'
import image2 from '../../src/assets/case2.gif'
import image3 from '../../src/assets/case3.gif'
import { getDatabase, onValue, ref, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { Flex, Image, Select, Tooltip, useToast } from '@chakra-ui/react';
import Loader from '../styles/Loader';
import gif from './Untitled design (5).gif'
import wave from './wave.gif'
import manual from './icons8-manual.gif';
import auto from './icons8-gears.gif'
import { Card, CardBody, Heading, Box, Text, Stack, StackDivider } from '@chakra-ui/react'
import { getSiteListWithProjectsAPI, projectDetailsAPI } from '../store/apis';
import InnerMapComponent from '../Components/Map/InnerMapComponent';
import axios from 'axios';
import { apiGetCallWithPromise } from '../store/axiosApicall';
import { apiName } from '../store/serverConfig';
var moment = require('moment');


let locations = [];
const center = {
  lat: 23.0363778,
  lng: 72.5416146,
};
let timeOutSiteDetails = null;
const SiteDashboard = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // debugger;  
  const siteReducer = useSelector(state => state.siteReducer);
  const [isFlip, setIsFlip] = useState(true);
  const [projectId, setProjectId] = useState('')
  const sites = useSelector((store) => store.siteReducer) || [];
  useEffect(() => {
    getProjectSiteGroups()
  }, [])
  const settingReducer = useSelector(state => state.settingReducer);
  const [siteDetails, setSiteDetails] = useState([]);
  const [currImage, setCurrImage] = useState(null);
  const [siteName, setSiteName] = useState("");
  const [siteId, setSiteId] = useState("");
  const [IMEI, setIMEI] = useState('');
  const [lastUpdate, setLastUpdate] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [waterSupplied, setWaterSupplied] = useState("");
  const [flowRate, setFlowRate] = useState("");
  const [level, setLevel] = useState("");
  const [discharge, setDischarge] = useState("60 M3/H");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [panchaayat, setPanchaayat] = useState("");
  const [dmts, setDmts] = useState(false);
  const [popSerThSchemes, setPopSerThSchemes] = useState("");
  const [locWatSources, setLocWatSources] = useState("");
  const [scheme, setScheme] = useState("");
  const [yearOfComi, setYearOfComi] = useState("");
  const [noOfHabit, setNoOfHabit] = useState("");
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [IMEINu, setIMEINu] = useState('860987058180299');
  const [waterData, setWaterData] = useState([]);
  const [motorData, setMotorData] = useState([]);
  const [flushValve, setFlushValve] = useState("");
  const [flushValveStyl, setFlushValveStyl] = useState("valve-on");
  const [inletValve, setInletValve] = useState("");
  const [inletValveStyl, setInletValveStyl] = useState("valve-on");
  const [outletValve, setOutletValve] = useState(false);
  const [outletValveLocal, setOutletValveLocal] = useState(false);
  const [systemMode, setSystemMode] = useState(false);
  const [systemModeLocal, setSystemModeLocal] = useState(false);
  const [isSystemModeStatus, setIsSystemModeStatus] = useState(false);
  const [isOutletValveStatus, setIsOutletValveStatus] = useState(false);
  const [pumpStatus, setPumpStatus] = useState("");
  const [pumpStatusStyl, setPumpStatusStyl] = useState("valve-on");
  const [depthSensor, setDepthSensor] = useState("");
  const [todayProduction, setTodayProduction] = useState("");
  const [rating, setRating] = useState("");
  const [serverFile, setServerFile] = useState();
  const [projects, setProjects] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [totalEmp, setTotalEmp] = useState(0);
  const [parametersList1, setParametersList1] = useState([]);
  const [parametersList2, setParametersList2] = useState([]);
  const [parametersList3, setParametersList3] = useState([]);
  const [parametersList4, setParametersList4] = useState([]);
  const [parametersList5, setParametersList5] = useState([]);
  const [graphs, setGraphs] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [site, setSite] = useState('')
  const [siteDetailsLocal, setSiteDetailsLocal] = useState(null);
  const toast = useToast()
  let firstTime = true;

  // const [centerPoint, setCenterPoint]=useState({
  //     lat: 23.0363778,
  //     lng: 72.5416146,
  //   });
  const [centerPoint, setCenterPoint] = useState(null);
  const [centerPointDriver, setCenterPointDriver] = useState(null);
  const [locationList, setLocationList] = useState(locations);
  const [markerList, setMarkerList] = useState([]);
  const [isOutletValveChecked, setIsOutletValveChecked] = useState(false);

  useEffect(() => {
    const p = localStorage.getItem('selectedProject');
    setTimeout(() => {
      if (p) {
        setSite(location.state.siteId)
        setSelectedProject(p)
      }
    }, 2000)
  }, [])


  useEffect(() => {
    selectedSites.forEach((item) => {
      if (item._id == site)
        setSiteName(item.pumpName)
    })
  }, [site])

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  const [isActiveState, setIsActiveState] = useState(true);
  const [isActiveReason, setIsActiveReason] = useState('');

  let driverMarker = [];
  const location = useLocation();
  console.log("IMIMIMIMIMIMMM", location)

  const changeProject = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    const selectedName = selectedOption.text;
    setSelectedProject(e.target.value)
  }


  const handleSiteChange = (event) => {

    setSite(event);
  };



  useEffect(() => {
    ////////////////////////////////////////////////////////////
    /// Following code is to get the firebase database value 
    //////////////////////////////////////////////////////////// 
    const firebaseConfig = {
      apiKey: "AIzaSyBWTpB6tDBfW-2gK_4ygJeyihWtyAnvdj4",
      authDomain: "desire-o-and-m.firebaseapp.com",
      databaseURL: "https://desire-o-and-m.firebaseio.com",
      projectId: "desire-o-and-m",
      storageBucket: "desire-o-and-m.appspot.com",
      messagingSenderId: "820152855217"
    }
    const app = initializeApp(firebaseConfig);
    // Firebase.initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const commentsRef = ref(database);
    onChildAdded(commentsRef, (data) => {
      if (data.val().groupFor.toUpperCase() == "AQUALOGIX") {
        //console.log('onChildAdded-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
        addMarker(data, "A");
      }
    });

    onChildChanged(commentsRef, (data) => {
      const item = driverMarker.findIndex((item) => item.empData.empID == data.val().empID);
      // console.log('onChildChanged-item',item);
      if (parseInt(item) == -1) {
        if (data.val().groupFor.toUpperCase() == "AQUALOGIX") {
          //console.log('onChildChanged-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
          addMarker(data, "A");
        }

      }
      else {
        // console.log('onChildChanged-ELSE');
        if (data) {
          const markerListLocal = {
            lat: parseFloat(data.val().lat),
            lng: parseFloat(data.val().long),
            _id: data.val().uniqId,
            empData: data.val(),
          };
          driverMarker.splice(item, 1, markerListLocal);
          // console.log('onChildChanged-driverMarker',driverMarker);
          // console.log('onChildChanged-item',item);
          // console.log('onChildChanged-data',data);          
          if (data.val().groupFor.toUpperCase() == "AQUALOGIX") {
            //console.log('onChildChanged-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
            addMarker("", "E");
          }

        }
      }
    });

    onChildRemoved(commentsRef, (data) => {
      // console.log('data-onChildRemoved',data);
      // console.log('data-onChildRemoved-data',data);
      const item = driverMarker.findIndex((item) => item._id.toString() == data.val().uniqId.toString());
      if (parseInt(item) > -1) {
        // console.log('onChildChanged-ELSE');
        if (data) {
          driverMarker.splice(item, 1);
          addMarker("", "D");
        }
      }
    });
    ////////////////////////////////////////////////////////////

    setPermission();
  }, []);



  useEffect(() => {
    // console.log('SiteDashboard-useEffect-location',location);    
    // setSiteId(location.state.siteId);
    if (site && site.length > 0) {
      // setSiteName(location.state.siteName);
      fetchSiteDetails2();
      // timeOutSiteDetails = setInterval(() => { fetchSiteDetails2(); }, 30000);
    }
    // console.log('ProjectDetails-siteId',location.state.siteId);
    // console.log('ProjectDetails-siteName',location.state.siteName);     
    // fetchBasicInfo();
    // return () => clearInterval(timeOutSiteDetails);
  }, [site])


  useEffect(() => {
    // console.log('SiteDashboard-useEffect-location',location);    
    // setSiteId(location.state.siteId);
    if (location.state) {
      setSiteName(location.state.siteName);
      fetchSiteDetails2();
    }
    // console.log('ProjectDetails-siteId',location.state.siteId);
    // console.log('ProjectDetails-siteName',location.state.siteName);     
    // fetchBasicInfo();
    // return () => clearInterval(timeOutSiteDetails);
  }, [location])

  const siteApi = async () => {
    try {
      setIsLoading(true)
      const info = await apiGetCallWithPromise(apiName.siteDetailsAPI, `id=${site}`);
      const siteData = info.data
      console.log("CECK INODOOO", siteData)
      if (siteData) {
        setSiteDetailsLocal(siteData);
        setState(siteData.address.state);
        setIMEI(siteData.IMEI)
        setIsActiveReason(siteData.inActiveReason);
        setDistrict(siteData.address.district);
        setBlock(siteData.address.block);
        setPanchaayat(siteData.address.panchayat);
        setPopSerThSchemes(siteData.population);
        setScheme(siteData.scheme);
        setLatitude(siteData.latitude);
        setLongitude(siteData.longitude);
        setYearOfComi(siteData.yearCommissioning);
        setNoOfHabit(siteData.noConnections);
        setProjectId(siteData.projectID);
        setProjectName(siteData.projectName);
        setServerFile(siteData.logo);
        setWaterSupplied(siteData.cummFlowKL);
        setFlowRate(siteData.flowMeterPerHourClosring);
        setLocWatSources(siteData.sourceType);
        setLevel(siteData.pHSensor);
        setLastUpdate(siteData.parameterUpdatedDate);
        setIMEINu(siteData.IMEI);
        setTodayProduction(siteData.todayProduction);
        setRating(siteData.rating);
        // center = {
        //   lat:siteData.latitude,
        //   lng:siteData.longitude
        // };


        //#####################################//
        // API call to get dynamic parameters //
        // Note: As of now keep comment because client want to keep it commented.
        //#####################################//
        if (siteData.projectID)
          fetchProjectDetails(siteData.projectID);
        //#####################################//


        const locations = [{
          lat: parseFloat(siteData.latitude),
          lng: parseFloat(siteData.longitude),
          _id: siteData._id,
          lastUpdate: siteData.updatedDate,
          projectName: siteData.pumpName,
          pumpName: siteData.pumpName,
          site: {
            lastUpdate: siteData.updatedDate,
            projectName: siteData.pumpName,
            pumpName: siteData.pumpName,
          },
        }]
        setLocationList(locations);
        setCenterPoint({ lat: parseFloat(siteData.latitude), lng: parseFloat(siteData.longitude) });

        if (siteData.F1 == 0) {
          setFlushValve("Closed");
          setFlushValveStyl("valve-off");
        } else if (siteData.F1 == "1") {
          setFlushValve("Open");
          setFlushValveStyl("valve-on");
        } else if (siteData.F1 == 2) {
          setFlushValve("In Process");
          setFlushValveStyl("valve-inprocess");
        } else if (siteData.F1 == 3) {
          setFlushValve("Error");
          setFlushValveStyl("valve-error");
        } else if (siteData.F1 == 4) {
          setFlushValve("Error");
          setFlushValveStyl("valve-error");
        }

        if (siteData.F3 == 0) {
          setInletValve("Closed");
          setInletValveStyl("valve-off");
        } else if (siteData.F3 == 1) {
          setInletValve("Open");
          setInletValveStyl("valve-on");
        } else if (siteData.F3 == 2) {
          setInletValve("In Process");
          setInletValveStyl("valve-inprocess");
        } else if (siteData.F3 == 3) {
          setInletValve("Error");
          setInletValveStyl("valve-error");
        } else if (siteData.F3 == 4) {
          setInletValve("Error");
          setInletValveStyl("valve-error");
        }

        if (siteData.F4 == 0) {
          setIsOutletValveChecked(false);
          setOutletValve(false);
        } else if (siteData.F4 == 1) {
          setIsOutletValveChecked(true);
          setOutletValve(true);
        } else if (siteData.F4 == 2) {
          setOutletValve(false);
        } else if (siteData.F4 == 3) {
          setOutletValve(false);
        } else if (siteData.F4 == 4) {
          setOutletValve(false);
        }

        if (siteData.F16 == 0) {
          setSystemMode(false);
        } else if (siteData.F16 == 1) {
          setSystemMode(true);
        } else if (siteData.F16 == 2) {
          setSystemMode(false);
        } else if (siteData.F16 == 3) {
          setSystemMode(false);
        } else if (siteData.F16 == 4) {
          setSystemMode(false);
        }

        if (siteData.F7 == 0) {
          setPumpStatus("Off");
          setPumpStatusStyl("valve-off");
        } else if (siteData.F7 == 1) {
          setPumpStatus("On");
          setPumpStatusStyl("valve-on");
        } else if (siteData.F7 == 2) {
          setPumpStatus("In Process");
          setPumpStatusStyl("valve-inprocess");
        } else if (siteData.F7 == 3) {
          setPumpStatus("Error");
          setPumpStatusStyl("valve-error");
        } else if (siteData.F7 == 4) {
          setPumpStatus("Error");
          setPumpStatusStyl("valve-error");
        }

        setDepthSensor(siteData.P3 ? siteData.P3.toFixed(2) + " Mtr" : "0 Mtr")
      } else {
        console.log("CECK INODOOO NOOo")

      }

    } catch (error) {
      console.error("Error fetching site details", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("SITE REDUCER CHECKING : ", siteReducer);
  }, [siteReducer])

  useEffect(() => {
    if (pumpStatus == "On" && inletValve == 'Open')
      setCurrImage(image1);
    else if (pumpStatus == "On" && flushValve == "Open")
      setCurrImage(image3);
    else
      setCurrImage(image2)

    console.log("CHECKING ALL VALUES", pumpStatus, flushValve, inletValve)
  }, [pumpStatus, flushValve, inletValve])
  useEffect(() => {
    // console.log('Dashboard-siteReducer-useEffect',siteReducer);    
    //comecomecome

    if (siteReducer.action) {
      ((!siteReducer.data || graphs.length == 0) && setIsLoading(true))
      if (siteReducer.action === types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS) {
        // console.log('ProjectDetails-siteReducer-useEffect',siteReducer)        
        if (siteReducer.status === "success") {
          if (siteReducer.data) {
            setSiteDetailsLocal(siteReducer.data);
            setState(siteReducer.data.address.state);
            setIMEI(siteReducer.data.IMEI)
            setIsActiveReason(siteReducer.data.inActiveReason)
            setDistrict(siteReducer.data.address.district);
            setBlock(siteReducer.data.address.block);
            setPanchaayat(siteReducer.data.address.panchayat);
            setPopSerThSchemes(siteReducer.data.population);
            setScheme(siteReducer.data.scheme);
            setLatitude(siteReducer.data.latitude);
            setLongitude(siteReducer.data.longitude);
            setYearOfComi(siteReducer.data.yearCommissioning);
            setNoOfHabit(siteReducer.data.noConnections);
            setProjectId(siteReducer.data.projectID);
            setProjectName(siteReducer.data.projectName);
            setServerFile(siteReducer.data.logo);
            setWaterSupplied(siteReducer.data.cummFlowKL);
            setFlowRate(siteReducer.data.flowMeterPerHourClosring);
            setLocWatSources(siteReducer.data.sourceType);
            setLevel(siteReducer.data.pHSensor);
            setLastUpdate(siteReducer.data.parameterUpdatedDate);
            setIMEINu(siteReducer.data.IMEI);
            setTodayProduction(siteReducer.data.todayProduction);
            setRating(siteReducer.data.rating);
            // center = {
            //   lat:siteReducer.data.latitude,
            //   lng:siteReducer.data.longitude
            // };


            //#####################################//
            // API call to get dynamic parameters //
            // Note: As of now keep comment because client want to keep it commented.
            //#####################################//
            if (siteReducer.data.projectID)
              fetchProjectDetails(siteReducer.data.projectID);
            //#####################################//


            const locations = [{
              lat: parseFloat(siteReducer.data.latitude),
              lng: parseFloat(siteReducer.data.longitude),
              _id: siteReducer.data._id,
              lastUpdate: siteReducer.data.updatedDate,
              projectName: siteReducer.data.pumpName,
              pumpName: siteReducer.data.pumpName,
              site: {
                lastUpdate: siteReducer.data.updatedDate,
                projectName: siteReducer.data.pumpName,
                pumpName: siteReducer.data.pumpName,
              },
            }]
            setLocationList(locations);
            setCenterPoint({ lat: parseFloat(siteReducer.data.latitude), lng: parseFloat(siteReducer.data.longitude) });

            if (siteReducer.data.F1 == 0) {
              setFlushValve("Closed");
              setFlushValveStyl("valve-off");
            } else if (siteReducer.data.F1 == "1") {
              setFlushValve("Open");
              setFlushValveStyl("valve-on");
            } else if (siteReducer.data.F1 == 2) {
              setFlushValve("In Process");
              setFlushValveStyl("valve-inprocess");
            } else if (siteReducer.data.F1 == 3) {
              setFlushValve("Error");
              setFlushValveStyl("valve-error");
            } else if (siteReducer.data.F1 == 4) {
              setFlushValve("Error");
              setFlushValveStyl("valve-error");
            }

            if (siteReducer.data.F3 == 0) {
              setInletValve("Closed");
              setInletValveStyl("valve-off");
            } else if (siteReducer.data.F3 == 1) {
              setInletValve("Open");
              setInletValveStyl("valve-on");
            } else if (siteReducer.data.F3 == 2) {
              setInletValve("In Process");
              setInletValveStyl("valve-inprocess");
            } else if (siteReducer.data.F3 == 3) {
              setInletValve("Error");
              setInletValveStyl("valve-error");
            } else if (siteReducer.data.F3 == 4) {
              setInletValve("Error");
              setInletValveStyl("valve-error");
            }

            if (siteReducer.data.F4 == 0) {
              setIsOutletValveChecked(false);
              setOutletValve(false);
            } else if (siteReducer.data.F4 == 1) {
              setIsOutletValveChecked(true);
              setOutletValve(true);
            } else if (siteReducer.data.F4 == 2) {
              setOutletValve(false);
            } else if (siteReducer.data.F4 == 3) {
              setOutletValve(false);
            } else if (siteReducer.data.F4 == 4) {
              setOutletValve(false);
            }

            if (siteReducer.data.F16 == 0) {
              setSystemMode(false);
            } else if (siteReducer.data.F16 == 1) {
              setSystemMode(true);
            } else if (siteReducer.data.F16 == 2) {
              setSystemMode(false);
            } else if (siteReducer.data.F16 == 3) {
              setSystemMode(false);
            } else if (siteReducer.data.F16 == 4) {
              setSystemMode(false);
            }

            if (siteReducer.data.F7 == 0) {
              setPumpStatus("Off");
              setPumpStatusStyl("valve-off");
            } else if (siteReducer.data.F7 == 1) {
              setPumpStatus("On");
              setPumpStatusStyl("valve-on");
            } else if (siteReducer.data.F7 == 2) {
              setPumpStatus("In Process");
              setPumpStatusStyl("valve-inprocess");
            } else if (siteReducer.data.F7 == 3) {
              setPumpStatus("Error");
              setPumpStatusStyl("valve-error");
            } else if (siteReducer.data.F7 == 4) {
              setPumpStatus("Error");
              setPumpStatusStyl("valve-error");
            }

            setDepthSensor(siteReducer.data.P3 ? siteReducer.data.P3.toFixed(2) + " Mtr" : "0 Mtr")
          }
        } else {
          toast({
            title: siteReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
        setIsLoading(false)

      }
      else if (siteReducer.action === types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE) {
        toast({
          title: siteReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        setIsLoading(false)

      }
      else if (siteReducer.action === types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS) {
        setDmts(siteReducer.data.dmtsOption);
        if (siteReducer.status === "success") {
          // Success block to manage parameter dynamically
          const { parameters, graphs, feedbacks } = siteReducer.data || {};

          let siteParametersLocalList = [];
          let count = 0;

          if (parameters && Array.isArray(parameters)) {
            parameters.forEach((item, index) => {
              if (item && item.isActive && manageParameterObj(item)) {
                siteParametersLocalList.push(manageParameterObj(item));

                switch (Math.floor(index / 6)) {
                  case 0:
                    console.log("Parameter added to list 1:", item);
                    break;
                  case 1:
                    console.log("Parameter added to list 2:", item);
                    break;
                  case 2:
                    console.log("Parameter added to list 3:", item);
                    break;
                  case 3:
                    console.log("Parameter added to list 4:", item);
                    break;
                  case 4:
                    console.log("Parameter added to list 5:", item);
                    break;
                }

                count++;
              }
            });
          }

          const filteredGraphs = graphs?.filter(item => item && item.isActive) || [];
          const filteredFeedbacks = feedbacks?.filter(item => item && item.isActive) || [];

          setFeedbacks(filteredFeedbacks);
          setGraphs(filteredGraphs);

          const parameterLists = [
            { list: siteParametersLocalList.slice(0, 6), setState: setParametersList1 },
            { list: siteParametersLocalList.slice(6, 12), setState: setParametersList2 },
            { list: siteParametersLocalList.slice(12, 18), setState: setParametersList3 },
            { list: siteParametersLocalList.slice(18, 24), setState: setParametersList4 },
            { list: siteParametersLocalList.slice(24, 30), setState: setParametersList5 },
          ];

          parameterLists.forEach(({ list, setState }) => {
            setState(list || []);
          });

          setIsLoading(false);
        } else {
          toast({
            title: siteReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }

        if (siteReducer.action === types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS) {
          setIsLoading(true);
        } else if (siteReducer.action === types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS) {
          setIsLoading(false);
        }

        firstTime = false;
      }
    }
  }, [siteReducer.action]);

  useEffect(() => {
    if (settingReducer.action) {
      if (settingReducer.action === types.SET_IMEI_NUMBER_SUCCESS) {
        if (settingReducer.status === "success") {
          setIMEINu(settingReducer.IMEINumber);
        } else {
          toast({
            title: settingReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (settingReducer.action === types.SET_IMEI_NUMBER_FAILURE) {
        toast({
          title: settingReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
    }
  }, [settingReducer.action])

  const setPermission = async () => {
    setIsEdit(await checkPermission('Site Dashboard', 'edit'));
  }
  const addMarker = (data, type) => {
    if (type.toString() == "A") {
      if (data) {
        const markerListLocal = {
          lat: parseFloat(data.val().lat),
          lng: parseFloat(data.val().long),
          _id: data.val().uniqId,
          empData: data.val(),
        };
        driverMarker.push(markerListLocal)
        setCenterPointDriver({ lat: parseFloat(data.val().lat), lng: parseFloat(data.val().long) });
        setMarkerList(driverMarker);
      }
    }
    else if (type.toString() == "E") {
      setCenterPointDriver({ lat: parseFloat(driverMarker[0].lat), lng: parseFloat(driverMarker[0].lng) });
      setMarkerList(driverMarker);
    }
    else if (type.toString() == "D") {
      setCenterPointDriver({ lat: parseFloat(driverMarker[0].lat), lng: parseFloat(driverMarker[0].lng) });
      setMarkerList(driverMarker);
    }
  }

  const fetchSiteDetails2 = async () => {
    await siteApi()
  }


  useEffect(() => {
    if (IMEI && IMEI.toString().length > 0) {
      fetchActivityStatus();
    }
  }, [IMEI])

  useEffect(() => {
    console.log("IMEI CHANGING", IMEI);
  }, [IMEI])

  const fetchActivityStatus = async () => {
    try {
      const resp = await axios.post('http://mqtt.aqualogix.in:85/sites/getActivityStatusBySite', { IMEI });
      setIsActiveState(resp.data.resp.active);
    } catch (error) {

    }
  }
  const fetchSiteDetails = () => {
    if (isLoading) return;
    dispatch(getSiteDetails(location.state.siteId));
  }

  const fetchProjectDetails = (projectId) => {
    dispatch(getProjectDetails(projectId));
  }
  const onClickMapType = (mapTypeId) => {
    setMapTypeId(mapTypeId);
  }
  const handleOutletValveStatus = (e) => {
    let isChecked = e.target.checked;
    setOutletValveLocal(isChecked);
    setIsOutletValveStatus(true);
    setTimeout(() => {
      setIsOutletValveStatus(false);
    }, 60000)
    if (isChecked)
      setOutletValve(true);
    else
      setOutletValve(false);
    // 860987058180299
    const IOT = {
      "IMEI": IMEINu,
      "C2": isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT));
  }

  const handleAutoManualStatus = (e) => {
    let isChecked = e.target.checked;
    setSystemModeLocal(isChecked);
    setIsSystemModeStatus(true);
    // setTimeout(() => {
    //   setIsSystemModeStatus(false);
    // }, 10000)
    if (isChecked)
      setSystemMode(true);
    else
      setSystemMode(false);
    const IOT = {
      "IMEI": IMEINu,
      "C1": isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT));
  }

  const bindEmpList = () => {
    if (markerList) {
      if (markerList.length > 0) {
        // console.log('markerList',markerList);
        return markerList.map((item, index) =>
        (
          <li key={item._id} className="list-group-item border-0" >
            <div className="d-flex">
              <div className="flex-shrink-0"><img src={avtarImage} className="avatar avatar-xs avatar-4x3" alt="Image Description" /></div>
              <div className="flex-grow-1 ms-3">
                <h5 className="mb-1">{item.empData.empName + " " + item.empData.empID}</h5>
                <span className="text-success">Status: {item.empData.totalSites > 0 ? item.empData.inWorkingLnNo > 0 ? "Working" : "InTransite  " : "No Route Assigned"}</span>
              </div>
            </div>
          </li>
        )
        );
      }
    }
  }
  const bindSystemMode = () => {
    // console.log('isSystemModeStatus',isSystemModeStatus);

    return (
      <p className="site-name text-blue d-flex align-items-center">
        <div className={!isSystemModeStatus ? "autoManualswitch" : "autoManualswitch loadingOpacity"}>
          <input
            type="checkbox"
            checked={!isSystemModeStatus ? systemMode : systemModeLocal}
            name="autoManualswitch"
            className="autoManualswitch-checkbox"
            id="autoManual"
            disabled={isSystemModeStatus}
            onChange={(e) => handleAutoManualStatus(e)}
            tabIndex="2"
          />
          <label className="autoManualswitch-label" for="autoManual">
            <span className="autoManualswitch-inner"></span>
            <span className="autoManualswitch-switch"></span>
          </label>
        </div>
        {isSystemModeStatus ? <span className='loaderStyle'>Loading....</span> : <span className='loaderStyle'></span>}
      </p>
    )
  }

  const getPrecentage = (val) => {
    let value = "0";

    // Check if parametersList1 exists and has at least two elements
    if (parametersList1 && parametersList1.length > 0 && parametersList1[1].value) {
      // Extract the numeric value from parametersList1[1].value and set it to 'value'
      if (val == 1) return parametersList1[1].value
      value = +parametersList1[1].value.split(" ")[0];
    }
    if (val == 1) return "0%"

    console.log("PIKACHU 1", value);

    // Calculate the percentage based on the extracted value
    value = ((value / 100) * 12) + "%";

    console.log("PIKACHU", value);

    // Return the formatted percentage as a string
    return `${value}`;
  }


  const waterLevel = (val) => {
    let value = "0";

    // Check if parametersList1 exists and has at least two elements
    if (parametersList1 && parametersList1.length > 0 && parametersList1[1].value) {
      // Extract the numeric value from parametersList1[1].value and set it to 'value'
      if (val == 1) return parametersList1[1].value
      value = +parametersList1[1].value.split(" ")[0];
    }
    if (val == 1) return "0%"

    return value
  }
  const bindOutletValve = () => {
    // console.log('isOutletValveStatus',isOutletValveStatus);
    return (
      <p className="site-name text-red d-flex align-items-center">
        <div className={!isOutletValveStatus ? "opencloseswitch" : "opencloseswitch loadingOpacity"}>
          <input
            type="checkbox"
            checked={!isOutletValveStatus ? outletValve : outletValveLocal}
            name="opencloseswitch"
            className="opencloseswitch-checkbox"
            id="outletValve"
            disabled={isOutletValveStatus}
            onChange={(e) => handleOutletValveStatus(e)}
            tabIndex="2"
          />
          <label className="opencloseswitch-label" for="outletValve">
            <span className="opencloseswitch-inner"></span>
            <span className="opencloseswitch-switch"></span>
          </label>
        </div>
        {isOutletValveStatus ? <span className='loaderStyle'>Loading....</span> : <span className='loaderStyle'></span>}
      </p>
    )
  }

  //###########################################//
  //Function to manage parameter dynamically.
  //##########################################//
  const manageParameterObj = (obj) => {
    // console.log('manageParameterObj-obj',obj);
    // console.log('manageParameterObj-siteDetailsLocal',siteDetailsLocal);
    // console.log('manageParameterObj-obj.parameter.toUpperCase()',obj.parameter.toUpperCase());    
    if (siteDetailsLocal) {
      if (obj) {
        { console.log("xx this is my obj", obj) }
        if (obj.parameter) {
          if (obj.parameter.toUpperCase() == "P5") {
            return { "imgIc": require("../assets/icons/Turbidity.png"), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P1") {
            return { "imgIc": require('../assets/new icons/bar.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P12") {
            return { "imgIc": require('../assets/new icons/flowrate.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P13") {
            return { "imgIc": require('../assets/new icons/totalflow.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()] + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P4") {
            return { "imgIc": require('../assets/new icons/chlorine.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] / 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P2") {
            return { "imgIc": require("../assets/icons/LevelSensor.png"), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P8") {
            return { "imgIc": require('../assets/new icons/solorpower.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 100).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P10") {
            return { "imgIc": require('../assets/new icons/electric-current.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P3") {
            return { "imgIc": require('../assets/icons/Turbidity.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] / 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P7") {
            return { "imgIc": require('../assets/new icons/energy.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 100).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P9") {
            return { "imgIc": require('../assets/new icons/freq.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 100).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P11") {
            return { "imgIc": require('../assets/new icons/energy.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] / 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else {
            return { "imgIc": require('../assets/new icons/voltage.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
        }
      }
    }
  }

  //###########################################//
  //Function to manage graph dynamically.
  //##########################################//
  const manageGraphs = (type) => {
    return graphs.filter((item, index) => (item.title.trim() == type));
  }

  //###########################################//
  //Function to manage feedbacks dynamically.
  //##########################################//
  const manageFeedbacks = (type) => {
    return feedbacks.filter((item, index) => (item.title.trim() == type));
  }

  const imageStyles = {
    width: "100%",
    height: '600px',
  }
  if (window.innerWidth <= 767) {
    imageStyles.height = '350px';
  }


  //come
  useEffect(() => {
    if (selectedProject !== 'ALL') {
      const selectedProjectData = projects?.find((item) => item.project._id === selectedProject);
      setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
    } else {
      setSelectedSites([]);
    }
  }, [selectedProject]);


  const getProjectSiteGroups = async () => {
    const user = await getData(CONSTANTVALUE.USEROBJECT);
    // console.log("XPXP", user);
    const info = await getSiteListWithProjectsAPI(user.id)
    const filteredProjects = info.message.filter((item) => item.project && item.sites?.length > 0);
    setProjects(filteredProjects);
  }


  return (
    <div className="page">
      {console.log("IM SITE REDUCER", siteReducer.data)}
      <NavBar projectLogo={serverFile} />
      <div className="project-page-head">
        <div className="row py-4 m-0">
          <Flex justifyContent={'flex-start'} alignItems={'center'} ml={1} >
            <Select
              id="  SizeSelect"
              value={selectedProject}
              w={'25%'}
              variant={'filled'}
              onChange={(e) => changeProject(e)}
              color="black"
            >
              <option value={'ALL'}>Select Project</option>
              {projects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                <option key={item.project._id} value={item.project._id}>
                  {item.project.ProjectName}
                </option>
              ))}
            </Select>
            {selectedProject !== 'ALL' && (
              <Select
                w={'25%'}
                variant={'filled'}
                ml={2}
                mr={4}
                value={site}
                onChange={(e) => { handleSiteChange(e.target.value) }}
                color="black"
              >
                <option value={'ALL'}>Select Site</option>
                {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                  <option key={site._id} value={site._id} imei={site.IMEI}>
                    {site.pumpName}
                  </option>
                ))}
              </Select>
            )}
          </Flex>
          <div className="col-12 col-xl-7 col-xxl-7" style={{ marginTop: '20px' }}>
            <Badge.Ribbon
              text={`Inactive Reason : ${isActiveReason}`}
              color="red"
              placement='end'
              style={{ height: '50px', fontSize: '20px', display: isActiveState === false ? 'flex' : 'none', alignItems: 'center' }} // Only show when inActiveReason is false
            >
              <div className="box-content border-start" style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <h3 className="box-title">Scheme Name</h3>
                <p className="site-name">
                  {siteName}
                  {/* TW Gautam Namager, Mehsana Nagar Palika */}
                </p>
              </div>
            </Badge.Ribbon>
          </div>


          {isLoading && <Loader />}

          <div className="col-12 col-xl-5 col-xxl-5">
            <div className="row">
              <div className="col-12 col-md-6 border-start">
                <div className="box-content">
                  <h3 className="box-title" style={{ fontWeight: 'bold' }}>Last Updated</h3>
                  <div className="time">
                    <i className="icon-time flex-shrink-0"></i>{lastUpdate ? moment(lastUpdate).format("DD/MM/YYYY hh:mm:ss a") : "--/--/----"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 border-start">
                <div className="box-content">
                  <h3 className="box-title" style={{ fontWeight: 'bold' }}>Today's Production Ltr<br /><div className="box-subtitle">(12:00 AM to 11:59 PM)</div></h3>
                  <div className="person-name">{todayProduction + " Ltr"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        {!isLoading && <div className="row">
          <div className="col mb-4">
            <div className="card">
              <div className="card-body p-0">
                <div className="row py-3 py-md-0">
                  {manageFeedbacks('Pump Auto/Manual').length ? <div className="col-12 col-md-12 col-xl-3"><div className="box-content"><h3 className="box-title text-black">System Mode</h3>{isEdit ? bindSystemMode() : <p className="site-name text-blue d-flex align-items-center">{!isSystemModeStatus ? 'Manual' : 'Auto'}</p>}</div></div> : null}
                  <div className="col-12 col-md-12 col-xl-9">
                    <div className="row">
                      {manageFeedbacks('Pump on/off status').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">Pumps Status</h3><p className="site-name text-blue"><div className={"valve-status " + pumpStatusStyl}>{pumpStatus}</div></p></div></div> : null}
                      {manageFeedbacks('Flush Status').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">FLUSH VALVE</h3><p className="site-name text-blue"><div className={"valve-status " + flushValveStyl}>{flushValve}</div></p></div></div> : null}
                      {manageFeedbacks('Inlet Valve Status').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">INLET VALVE</h3><p className="site-name text-green"><div className={"valve-status " + inletValveStyl}>{inletValve}</div></p></div></div> : null}
                      {manageFeedbacks('Outlet Valve').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">OUTLET VALVE</h3>{isEdit ? bindOutletValve() : <p className="site-name text-blue d-flex align-items-center">{!isOutletValveStatus ? 'Close' : 'Open'}</p>}</div></div> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {parametersList1 ? <SensorValue data={parametersList1} /> : null}
        {parametersList2 ? <SensorValue data={parametersList2} /> : null}
        {parametersList3 ? <SensorValue data={parametersList3} /> : null}
        {parametersList4 ? <SensorValue data={parametersList4} /> : null}
        {parametersList5 ? <SensorValue data={parametersList5} /> : null}
        <div className="row" style={{ height: "700px" }}>
          {!isFlip && <div className="col-12 col-md-8 col-xxl-9 mb-4">

            <div className="card">
              <div className="card-header d-flex flex-column flex-xl-row justify-content-between">
                <h4 className="card-header-title  mb-3 mb-xl-0">{projectName}</h4>
                <div
                  className="btn-group project-btn-group"
                  role="group"
                  aria-label="Default button group">
                  {/* <button type="button" className="btn btn-outline-dark" onClick={() => onClickMapType('roadmap')}>Maps</button>
                  <button type="button" className="btn btn-outline-dark" onClick={() => onClickMapType('satellite')}>Satellite</button> */}
                  <button type="button" className="btn btn-outline-dark" onClick={() => setIsFlip(!isFlip)}>{isFlip ? "View Map" : "View Pump Diagram"}</button>
                </div>
              </div>
              <div className="card-body" style={{ height: "663px" }}>
                <div className="map-area" style={{ marginBottom: "50px" }}>
                  <InnerMapComponent latitude={latitude} siteName={siteName} longitude={longitude} siteReducer={siteReducer?.data?.siteDetails?.filter((item) => item.pumpName == siteName)} />
                </div>
              </div>
            </div>
          </div>
          }

          {isFlip && <div className="col-12 col-md-8 col-xxl-9  mb-4">
            <div className="card" >
              <div className="card-header d-flex flex-column flex-xl-row justify-content-between">
                <h4 className="card-header-title  mb-3 mb-xl-0" >Pump Diagram View</h4>
                <div
                  className="btn-group project-btn-group"
                  role="group"
                  aria-label="Default button group" >
                  <button type="button" style={{ height: "100%" }} className="btn btn-outline-dark" onClick={() => setIsFlip(!isFlip)}>View Map</button>
                </div>

              </div>


              <div className="card-body" >

                <div className="map-area  " style={{ position: "relative", height: "635px" }}>
                  <img src={currImage} alt="Empty Water Tank" style={imageStyles} />
                  {isOutletValveStatus === "true" && (<Tooltip label="Outlet Valve is ON"> <img
                    className="outlet-valve"
                    src={gif}
                    style={{
                      height: `4.7%`,
                      width: '3.1%',
                      backgroundColor: 'white',
                      position: "absolute",
                      bottom: '29.9%',
                      left: '81%',
                      transform: 'translateX(-50%)',
                      transition: 'height 0.5s ease',
                      animation: 'wave 3s infinite linear'
                    }}
                  >
                  </img></Tooltip>)}
                  {flushValve === "Open" && (<Tooltip label="Flush Valve Is ON"><img
                    className="flush-valve"
                    src={gif}
                    style={{
                      height: `4.7%`,
                      width: '2.6%',
                      backgroundColor: 'while',
                      position: "absolute",
                      bottom: '16%',
                      left: '21.10%',
                      transform: 'translateX(-50%)',
                      transition: 'height 0.5s ease',
                      animation: 'wave 3s infinite linear'
                    }}
                  >
                  </img></Tooltip>)}

                  {/* {inletValve === "Open" && (
                    <Tooltip label="Inlet Valve is ON">
                      <img
                        className="inlet-valve"
                        src={gif}
 
                        style={{
                          zIndex: 99,
                          height: `4.4%`,
                          width: '3%',
                          backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                          position: "absolute",
                          bottom: '33.5%',
                          left: '31.8%',
                          transform: 'translateX(-50%)',
                          transition: 'height 0.5s ease',
                          animation: 'wave 3s infinite linear'
                        }}
                      />
                    </Tooltip>
                  )} */}
                  <div
                    className="system-mode"
                    style={{
                      height: `12%`,
                      width: '40%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '63%',
                      left: '6.5%',
                    }}
                  > System Mode : {!isSystemModeStatus ? "MANUAL" : "AUTO"}
                  </div>
                  <div
                    className="waterlevel"
                    style={{
                      height: `3%`,
                      width: '20%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '82%',
                      left: '64%',
                    }}
                  > {waterLevel() + "%"}
                  </div>
                  <div
                    className="depth"
                    style={{
                      height: `0%`,
                      width: '6%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '15%',
                      left: '3%',
                    }}
                  > {parametersList1[2]?.value}
                  </div>

                  <div
                    className="pressure"
                    style={{
                      height: `0%`,
                      width: '20%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '59%',
                      left: '21%',
                    }}
                  > {parametersList1[0]?.value}
                  </div>
                  <div
                    className="flowrate"
                    style={{
                      height: `0%`,
                      width: '20%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '54%',
                      left: '38%',
                    }}
                  > {parametersList2[4]?.value}
                  </div>

                  <Tooltip label={parametersList1[2]?.value}>


                    <div
                      className="depth"
                      style={{
                        height: `5%`,
                        width: '10%',
                        fontWeight: 'bolder',
                        position: "absolute",
                        bottom: '10%',
                        fontSize: '0.7rem',
                        left: '0.5%',
                      }}
                    >
                      {/* {parametersList1[2]?.value} */}
                    </div>

                  </Tooltip>

                  <Tooltip label={parametersList1[0]?.value}>
                    <div
                      className="bar pressure"
                      style={{
                        height: `5%`,
                        width: '10%',
                        fontWeight: 'bolder',
                        position: "absolute",
                        bottom: '55%',
                        fontSize: '0.7rem',
                        left: '20%',
                      }}
                    >
                      {/* {parametersList1[0]?.value} */}
                    </div>

                  </Tooltip>
                  <Tooltip label={parametersList2[4]?.value}>
                    <div
                      className="flowRate"
                      style={{
                        height: `5%`,
                        width: '7%',
                        fontWeight: 'bolder',
                        position: "absolute",
                        bottom: '33%',
                        fontSize: '0.7rem',
                        left: '38%',
                      }}
                    >
                      {/* {parametersList2[4]?.value} */}
                    </div>

                  </Tooltip>

                  <img
                    className="system-mode"
                    src={!isSystemModeStatus ? auto : manual}
                    style={{
                      height: `12%`,
                      width: '12%',
                      backgroundColor: 'while', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '77%',
                      left: '12%',
                      transform: 'translateX(-50%)',
                      transition: 'height 0.5s ease',
                      animation: 'wave 3s infinite linear'
                    }}
                  />
                  {pumpStatus == "On" && inletValve == 'Open' &&
                    <div
                      className="doser"
                      style={{
                        height: '2.4%',
                        width: '1.4%',
                        backgroundColor: 'green',
                        position: "absolute",
                        bottom: '33.5%',
                        left: '49.7%',
                        borderRadius: "100%",
                      }}>
                    </div>
                  }
                  <Tooltip label={getPrecentage(1)} fontSize="md" hasArrow>
                    <img
                      className="water-level image-container"
                      src={gif}
                      style={{
                        height: `${getPrecentage()}`,
                        width: '13.5%',
                        backgroundColor: 'blue',
                        position: "absolute",
                        bottom: '67.4%',
                        left: '65.62%',
                        transform: 'translateX(-50%)',
                        transition: 'height 0.5s ease',
                        animation: 'wave 3s infinite linear'
                      }}
                    >
                    </img>
                  </Tooltip>

                </div>
              </div>
            </div>
          </div>}

          <div className="col-12 col-md-4 col-xxl-3 mb-2 ">



            <div className="card advanced-info" style={{ height: 'max-content' }} onClick={() => { siteApi() }} >
              <div className="card-body   p-0">
                <div className="panel_content">
                  <h2>Advanced Information</h2>
                  <ul className="basicinfo-list scroll" style={{ height: 'max-content' }}>
                    <li>
                      <span className="">State</span>
                      <span className="amount">{state}</span>
                    </li>
                    <li>
                      <span className="">District</span>
                      <span className="amount">{district}</span>
                    </li>
                    <li>
                      <span className="">Block</span>
                      <span className="amount">{block}</span>
                    </li>
                    <li>
                      <span className="">Panchaayat</span>
                      <span className="amount">{panchaayat}</span>
                    </li>
                    <li>
                      <span className="title">Population served through schemes</span>
                      <span className="amount">{popSerThSchemes}</span>
                    </li>
                    <li>
                      <span className="">Location Water sources</span>
                      <span className="amount">{locWatSources}</span>
                    </li>
                    <li>
                      <span className="">Scheme</span>
                      <span className="amount">{scheme}</span>
                    </li>
                    <li>
                      <span className="">Year of Comissioning</span>
                      <span className="amount">{yearOfComi}</span>
                    </li>

                    {/* <li>
                      <span className="">Tubewell Waterlevel</span>
                      <span className="amount">
                        {siteReducer.data && typeof siteReducer.data.P3 === 'number'
                          ? (siteReducer.data.P3 / 10).toFixed(2) + " Mtr"
                          : "0 Mtr"}
                      </span>
 
                    </li> */}
                    <li>
                      <span className="">Pump Rating</span>
                      {/* <span className="amount">{siteReducer.data ? siteReducer.data.rating : ""}</span> */}
                      <span className="amount">{siteReducer.data && siteReducer.data.siteDetails && siteReducer?.data?.siteDetails.length > 0 ? siteReducer.data.siteDetails[0].rating : ""}</span>

                    </li>
                  </ul>
                </div>

              </div>


            </div>

          </div>
        </div>


        <div className="row">
          <div className="col-lg mb-4">
            <div className="card">
              {isLoading && <Loader />}
              {!isLoading && <div className="card-body">
                <div className="row">
                  {manageGraphs("LPCD").length ? <div className="col-md-4 mb-3">{location.state ? <CardLPCD content={"Card-1"} title={'LPCD: Litres per capita per day'} type={'B'} graphFor="lpcd" siteId={site} /> : null}</div> : null}
                  {manageGraphs("Chlorine").length ? <div className="col-md-4 mb-3">{location.state ? <CardChlorine content={"Card-1"} title={'Residual Chlorine (mg/l)'} type={'L'} graphFor="chlorine" siteId={site} /> : null}</div> : null}
                  {manageGraphs("Pressure").length ? <div className="col-md-4 mb-3">{location.state ? <CardPressure content={"Card-1"} title={'Pressure (meter)'} type={'A'} graphFor="pressure" siteId={site} /> : null}</div> : null}
                  {manageGraphs("Achieved Litres").length ? <div className="col-md-4 mb-3">{location.state ? <CardWatersupplied content={"Card-1"} title={'Achieved Ltr vs last 7 days'} type={'L'} graphFor="watersupplied" siteId={site} /> : null}</div> : null}
                  {manageGraphs("Turbidity").length ? <div className="col-md-4 mb-3">{location.state ? <CardTurbidity content={"Card-1"} title={'Turbibity(NTU)'} type={'A'} graphFor="turbidity" siteId={site} /> : null}</div> : null}
                  {manageGraphs("OHSR").length ? <div className="col-md-4 mb-3">{location.state ? <CardOHRS content={"Card-1"} title={'OHSR Level'} type={'L'} graphFor="ohsr" siteId={site} /> : null}</div> : null}
                </div>
              </div>}
            </div>
          </div>
        </div>
        {dmts && <div className="row">
          <div className="col mb-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-8 col-xxl-9">
                    <div className="card operation-tracker-card">
                      <div className="card-header d-flex justify-content-between px-0 ">
                        <h4 className="card-header-title">Operation and Maintenance Tracker</h4>
                        <span>Total NUMBER OF EMPLOYEE: {markerList ? markerList.length : 0}</span>
                      </div>
                      <div className="card-body px-0">
                        <div className="map-area">
                          {/* {bindMapDriver()} */}
                          <InnerMapComponent latitude={latitude} siteName={siteName} longitude={longitude} siteReducer={siteReducer?.data?.siteDetails?.filter((item) => item.pumpName == siteName)} />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xxl-3">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="card">
                          <div className="card-header card-header-content-between"><h4 className="card-header-title">Employee LIVE LOCATION STATUS</h4></div>
                          <div className="card-body " style={{ height: "590px" }}><ul className="list-group list-group-flush list-group-no-gutters scroll">{bindEmpList()}</ul></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default SiteDashboard;